@charset 'utf-8';
@import 'bootstrap';

.mrT10 {
  margin-top: 10px;
}

.mrB10 {
  margin-bottom: 10px;
}

.mrB0 {
  margin-bottom: 0;
}
.fff {
  color: #fff;
  &:hover {
    color: #fff;
  }
}

dt:before {
  content: '■';
}
dd {
  margin-bottom: 7px;

  &:last-child {
    margin-bottom: 0;
  }
}

* {
  font-family: 'Hiragino Kaku Gothic ProN', 'ヒラギノ角ゴ ProN W3', 'Meiryo UI' , 'メイリオ', Meiryo, 'ＭＳ Ｐゴシック', 'MS PGothic', sans-serif;
  font-feature-settings : 'palt';
}

header {
  background-color: #0063c5;
  margin-bottom: 20px;
  padding: 20px 0;
  p {
    margin-bottom: 0;
    color: #fff;
  }
}

#form {
  padding: 5px 20px;
}

body {
  background-color: #e9f6ff;
}

main {
  margin-bottom: 150px;
}

h1 {
  margin-bottom: 40px;
  padding-bottom: 6px;
  border-bottom: 2px solid #999;
  font-size: 25px;
  font-weight: 700;
}

h2 {
  margin-top: 0;
  margin-bottom: 5px;
  color: #067100;
  font-size: 24px;
  font-weight: 700;
}

h3 {
  font-size: 16px;
  margin-top: 0;
}

.h3__view {
  font-size: 16px;
  margin-bottom: 15px;
  border-bottom: 1px solid #ccc;
  padding-bottom: 18px;
}

table {
  font-size: 12px;
}

.table {
  margin-bottom: 0;
}

.top p:last-child {
  margin-bottom: 0;
}

thead {
    background-color: #fcffc6;
}

tbody td {
  background-color: #fff;
  vertical-align: middle !important;
}

thead tr:first-child {
  background-color: #f3f3f3;
}

.well {
  background-color: #fffdf9;
}

.scroll {
  display: none;
}

td {
  font-size: 16px;
}
.well__search {
  .col-sm-6 {
    &:first-child {
      background-color: #c0e0ff;
      padding: 20px;
    }
    &:last-child {
      background-color: #ffd9b9;
      padding: 20px;
    }
  }
}

.red {
  color: red;
}

.w100 {
  width: 100%;
}

.white {
  color: white;
}

.block {
  display: block;
}

@media (max-width: $screen-xs-max) {
  .address {
    margin-bottom: 100px !important;
  }
  .scroll {
    display: block;
  }

  .sp-mb20 {
    margin-bottom: 20px;
  }

  .col-sm-5 {
    margin-bottom: 20px;
  }

  .btn--outer {
    margin-bottom: 0;
  }

  .table-responsive {
    th {
      text-align: left;
    }
  }
}

select {
  font-weight: 700;
}
select#description {
//  background-color: #ffffda;
//  animation-name: select;
  animation-duration: 3s;
  animation-iteration-count: infinite;
  option {
    &:first-child {
      font-weight: 700;
    }
  }
}

.filter {
  filter: drop-shadow(0 0 5px rgba(50, 50, 50, .8));
}
header {
  p {
    a {
      width: 100%;
    }
  }
}
.scroll-top{
  bottom: 65px;
  right: 16px;
  height: 41px;
  padding: 11px;
  position: fixed;
}

aside {
  ul {
    margin-left: 20px;
    padding-left: 0;
    font-size: 16px;
  }
}
footer {
  background-color: #0063c5;
  margin-top: 150px;
  font-size: 80%;
  dl {
    margin-bottom: 0;
    padding: 10px 0;
    dd,
    dt,
    a {
      color: #fff;
      word-break: break-all;
    }
    p {
      margin-bottom: 0;
    }
  }
}

/* Sticky footer styles
-------------------------------------------------- */
html {
  position: relative;
  min-height: 100%;
}

footer {
  position: absolute;
  bottom: 0;
  width: 100%;
}

/* Custom page CSS
-------------------------------------------------- */
/* Not required for template or sticky footer method. */
.container {
  width: auto;
  max-width: 680px;
  padding: 0 15px;
}

@keyframes select {
  0% {
  background-color: #ffffda;
  }
  50% {
  background-color: #ff0;
  }
  100% {
  background-color: #ffffda;
  }
}

#map {
  height: 425px;
}

nav {
  padding: 10px 0;
  ul {
    margin-bottom: 0;
    padding-left: 0;
    width: 100%;
    display: flex;
    justify-content: space-around;

    li {
      text-align: center;
      list-style-type: none;

      a {
        padding: 10px 2px;
      }
    }
  }
}

@media (max-width: 1050px) {
  nav {
    padding: 5px;
    ul {
      display: inline !important;

      li {
        display: block;
        margin-bottom: 10px;
        &:last-child {
          margin-bottom: 0;
        }

        a {
          width: 100%;
        }
      }
    }
  }
}

